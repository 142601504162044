<template>
    <div id="news">
        <toolbar-component :title="$t('news_page.actualities')" />

        <div v-if="!loading_articles" class="mx-auto">
            <v-row class="ma-0 pa-4">
                <v-col v-for="article in articles" :key="article.title" cols="12" lg="4" md="6">
                    <v-card class="mx-auto article-wrapper" height="100%" max-width="600px">
                        <v-card-title class="pa-0">
                            <img :alt="article.image_alt" :src="article.image" width="100%" />
                        </v-card-title>

                        <v-card-text class="pt-2">
                            <span class="font-18 secondary--text">{{ article.title }}</span>
                        </v-card-text>

                        <v-card-actions class="justify-space-between font-14">
                            <span>{{ article.date }}</span>
                            <a :href="article.link" target="_blank">
                                <v-icon class="mb-1" color="primary" small>mdi-open-in-new</v-icon>
                                {{ $t('news_page.read_more') }}
                            </a>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </div>

        <v-row v-if="loading_articles" class="ma-0 pa-4">
            <v-col v-for="i in 6" :key="i" cols="12" lg="4" md="6">
                <v-skeleton-loader loading type="image, list-item-three-line, list-item" />
            </v-col>
        </v-row>

        <div class="text-center mb-6">
            <a class="secondary--text font-weight-bold text-decoration-none" href="https://oleo100.com/actualites/articles" target="_blank">
                {{ $t('news_page.find_all_article_on_site') }}
                <v-icon color="secondary">mdi-arrow-right-thin</v-icon>
            </a>
        </div>

        <div class="d-flex justify-center gap-2 mb-2">
            <a class="text-decoration-none" href="https://www.linkedin.com/company/oleo100/?originalSubdomain=fr" target="_blank">
                <v-icon> mdi-linkedin</v-icon>
            </a>
            <a
                class="text-decoration-none"
                href="https://www.facebook.com/Oleo100"
                target="_blank"
            >
                <v-icon> mdi-facebook</v-icon>
            </a>
            <a
                class="text-decoration-none"
                href="https://twitter.com/Oleo100_FR"
                target="_blank"
            >
                <v-icon> mdi-twitter</v-icon>
            </a>
        </div>
    </div>
</template>

<script>
import ToolbarComponent from '@/components/Global/ToolbarComponent'
import GlobalRepository from '@/repositories/GlobalRepository'

export default {
    name: 'news',
    components: { ToolbarComponent },
    data() {
        return {
            articles: [],
            loading_articles: false,
        }
    },
    created() {
        this.getScrapedArticles()
    },
    methods: {
        getScrapedArticles() {
            this.loading_articles = true
            GlobalRepository.getScrapedArticles()
                .then((success) => {
                    this.articles = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_articles = false
                })
        },
    },
}
</script>

<style>
.article-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
</style>
